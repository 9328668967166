.App {
  max-width: 800px;
  margin: auto;
  margin-top: 4.5em;
  font-family: 'Josefin Sans', sans-serif;
}

input {
  padding: 4px 8px;
  border-radius: 5px;
  border: 1px solid gray;
}

/* --- TO REMOVE + AND - FROM INPUT NUMBER --- */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* --- TO REMOVE + AND - FROM INPUT NUMBER --- */

/* MEDIA QUERIES */
@media screen and (max-width: 600px) {
  input {
    padding: 2px;
  }
}

